import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import routesConfig from '@mindfulchefuk/config/routesConfig'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { CMSRedirects } from '@mindfulchefuk/features/CMS/interfaces'
import { useCMSRecipeDeliveryURL } from '@mindfulchefuk/features/CMS/hooks/useCMSRecipeDeliveryURL'
import {
  Products,
  ProductType,
  useQueryAllProducts,
} from '@mindfulchefuk/query'
import { useNextAvailableRecipesDate } from '@mindfulchefuk/features/Recipes/hooks'

export type TUseCMSBannerRedirectResponse = {
  redirectURL: string
  targetDeliveryDate: Date
  analytics: {
    hasRecipeDelivery: boolean
  }
} | null

export const useCMSBannerRedirect = (
  redirectConfig?: CMSRedirects
): TUseCMSBannerRedirectResponse => {
  const [redirectURL, setRedirectURL] = useState(null)
  const router = useRouter()
  const urlDeliveryDate = (router.isReady &&
    router.query.deliveryDate) as string

  const isLoggedIn = isAuthenticated()
  const { isNewCustomer } = useIsNewCustomer()
  const isLoggedOut = !isLoggedIn || isNewCustomer

  const { data: products } = useQueryAllProducts()

  //make sure the url date is available for shop order
  const nextDeliveryDate = useNextAvailableRecipesDate(urlDeliveryDate)
  const {
    url: recipeRedirectUrl,
    hasDelivery: hasRecipeDelivery,
    targetDeliveryDate,
  } = useCMSRecipeDeliveryURL(redirectConfig?.recipeDelivery, isLoggedOut)

  const setRedirectURLHandler = useCallback(
    (config: CMSRedirects) => {
      if (!config) return setRedirectURL(null)
      const { type, url, productDetail, productTab } = config
      switch (type) {
        case 'recipeDelivery':
          return setRedirectURL(recipeRedirectUrl)
        case 'url':
          return setRedirectURL(url.path)
        case 'productDetail':
          return setRedirectURL(
            getProductDetailUrl(
              products,
              productDetail?.productId,
              isLoggedOut,
              nextDeliveryDate
            )
          )
        case 'productTab':
          return setRedirectURL(
            getProductTabUrl(
              productTab?.productType,
              isLoggedOut,
              nextDeliveryDate
            )
          )
        default:
          return setRedirectURL(null)
      }
    },
    [isLoggedOut, products, recipeRedirectUrl, nextDeliveryDate]
  )

  useEffect(() => {
    setRedirectURLHandler(redirectConfig)
  }, [redirectConfig, setRedirectURLHandler])

  return {
    redirectURL,
    targetDeliveryDate,
    analytics: {
      hasRecipeDelivery,
    },
  }
}

export const getProductDetailUrl = (
  products: Products,
  productId: number,
  isLoggedOut: boolean,
  nextDeliveryDate: string
): string => {
  if (!products || !products.length || !productId) return null

  if (isLoggedOut) {
    return routesConfig.chooseRecipes.pathname
  }

  const product = products.find((p) => p.id === productId.toString())

  if (!product) return null

  const { productType } = product

  return `${routesConfig.delivery.pathname(
    nextDeliveryDate
  )}?page=${productType}&pid=${productId}`
}

export function getProductTabUrl(
  productType: ProductType,
  isLoggedOut: boolean,
  nextDeliveryDate: string
) {
  if (!productType) return null
  if (isLoggedOut) {
    return `${routesConfig.chooseRecipes.pathname}`
  }
  return `${routesConfig.delivery.pathname(
    nextDeliveryDate
  )}?page=${productType}`
}
