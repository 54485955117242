import React, { ReactNode } from 'react'

import { CategoryName, ProductType } from '@mindfulchefuk/query'

type TProductTypeConfig = {
  id: ProductType
  name: CategoryName
  title: string
  shortTitle: string // No more characters than "Gut-boosting-breakfasts"
  image: string
  description?: ReactNode
}

type TProductTypeConfigs = Record<ProductType, TProductTypeConfig>

const productTypeConfig: TProductTypeConfigs = {
  recipes: {
    id: 'recipes',
    name: 'Recipes',
    shortTitle: 'Recipes',
    title: 'Recipes',
    image: 'navigation/recipe_box.jpg',
  },
  meal: {
    id: 'meal',
    name: 'Ready meals',
    shortTitle: 'Frozen ready meals',
    title: 'Healthy frozen ready meals',
    image: 'navigation/meal.jpg',
    description: (
      <p>
        Introducing our new lineup of healthy frozen ready meals. We&apos;ve
        taken your favourite recipes and high-quality ingredients (from
        delicious free-range chicken to vibrant veggies) and transformed them
        into freezer favourites. They&apos;re speedy, convenient and
        nutritionally-balanced; containing no gluten or dairy.
      </p>
    ),
  },
  smoothie: {
    id: 'smoothie',
    name: 'Smoothies',
    shortTitle: 'Nutritious smoothies',
    title: 'Healthy Frozen Smoothies',
    image: 'navigation/smoothie.jpg',
    description: (
      <p>
        Meet our ready-to-blend smoothie mixes: bursting with vibrant frozen
        fruit, vitamins and fresh flavour. Ideal for quick breakfasts, health
        kicks and afternoon pick-me-ups. They contain no dairy, gluten, or
        refined sugar – just blend with your favourite liquid and you&apos;re
        ready to rock.
      </p>
    ),
  },
  frozen_desserts: {
    id: 'frozen_desserts',
    name: 'Desserts',
    shortTitle: 'Snacks',
    title: 'Mindful Snacks',
    image: 'navigation/frozen_desserts.jpg',
    description: (
      <p>
        You&apos;ve had your healthy dinner, now it&apos;s time for the finale!
        Meet our feel-good snacks range which can also help you throughout your
        busy day - they are all gluten-free!
      </p>
    ),
  },
  broths_and_soups: {
    id: 'broths_and_soups',
    name: 'Soups',
    shortTitle: 'Soups',
    title: 'Satisfying soups',
    image: 'navigation/broths_and_soups.jpg',
    description: (
      <p>
        Big on flavour, low in fat and perfect for lunch and light suppers. Our
        satisfying soups are packed with protein, seasonal veg, and a healthy
        Mindful Chef twist. That’s it. No preservatives, no nasties - just high
        quality ingredients to help you feel good.
      </p>
    ),
  },
  breakfasts: {
    id: 'breakfasts',
    name: 'Breakfast',
    shortTitle: 'Healthy breakfasts',
    title: 'Healthy breakfasts',
    image: 'navigation/breakfasts.jpg',
    description: (
      <p>
        Say hello to our mindful mix of gut-friendly granolas and mueslis.
        Packed with fibre and natural goodness from crunchy seeds, nuts and
        dried fruit – they&apos;ve got everything you need for a brilliant start
        to the day. Even better? They&apos;re free from refined sugars, dairy
        and gluten. Breakfast heaven in every bite.
      </p>
    ),
  },
  snacks: {
    id: 'snacks',
    name: 'Snacks & Vitamins',
    shortTitle: 'Vitamins',
    title: 'Vitamins',
    image: 'navigation/snacks.jpg',
    description: (
      <p>
        Elevate your daily routine with our hand-picked selection of functional
        vitamins and healthy treats. Our team has chosen supplements from top
        brands to support your health goals. Whether you&apos;re prioritising
        digestion, immunity, skin, joint or bone health, we’ve got you covered.
      </p>
    ),
  },
  bundled_products: {
    id: 'bundled_products',
    name: 'Healthy Bundles',
    shortTitle: 'Healthy bundles',
    title: 'Healthy Bundles',
    image: 'navigation/bundled_products.jpg',
    description: (
      <p>
        Simplify everything with our one-stop bundle shop. Looking for
        gut-friendly breakfasts you can rely on every morning? Speedy frozen
        ready meals to load into the freezer for busy months? Or, perhaps some
        delicious drinks and treats for healthier snacking throughout the week?
        Find the bundle that does it all, and perfectly complements your healthy
        recipe box.
      </p>
    ),
  },
}

const { recipes, ...shopProductTypeConfig } = productTypeConfig

const isProductType = (type: string | undefined): type is ProductType =>
  Object.keys(productTypeConfig).includes(type)

const isShopProductType = (type: string | undefined): type is ProductType =>
  Object.keys(shopProductTypeConfig).includes(type)

export {
  isProductType,
  isShopProductType,
  productTypeConfig,
  shopProductTypeConfig,
}
